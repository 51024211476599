

import { getSelectedVariant } from '@yggdrasil/Order/ProductSelect/utils'
import { CF2Component } from 'javascript/lander/runtime'

export default class ProductSelectV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {

    this.variantValues = Object.entries(this.product.property_values_variant_mapping ?? {}).reduce((acc, entries) => {
      const values = entries[0].split(',').map((value) => parseInt(value))
      acc[entries[1]] = values
      return acc
    }, {}) ?? {}

    this.valuesPositions = this.product.sorted_property_values?.reduce((acc, property) => {
      property.value_ids.forEach((value, index) => {
        acc[value] = index
      })
      return acc
    }, {}) ?? {}

    this.registerVariantEventListeners()
  }

  renderAndMount() {
    this.render()
    this.registerVariantEventListeners()
  }

  registerVariantEventListeners() {
    const variantSelects = this.element.querySelectorAll('.elVariantSelector')
    variantSelects.forEach((select, index) => {
      select.addEventListener('click', (evt) => {
        evt.stopImmediatePropagation()
        evt.stopPropagation()
        evt.preventDefault()
      })

      select.addEventListener('change', (evt) => {
        evt.stopImmediatePropagation()
        evt.stopPropagation()
        evt.preventDefault()

        const newValues = [...variantSelects].map((e) => e.value)
        
        const selectedVariantId = getSelectedVariant(this.product.sorted_property_values, 
          this.product.property_values_variant_mapping, 
          this.variantValues, this.valuesPositions, index, newValues
        )

        const newVariant = this.product.variants.find((v) => v.id == String(selectedVariantId))
        this.currentVariant = newVariant
        this.selected_price = this.currentVariant.prices[0]
        this.renderAndMount()
      })
    })
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const product = this.product ?? null ;
const currentVariant = this.currentVariant ?? null ;
const selected_price = this.selected_price ?? null ;
const button_layout_size = this.button_layout_size ?? null ;
const input_layout_size = this.input_layout_size ?? null ;

    const editor_product = this.element.getAttribute('data-param-editor_product');
const primary_text = this.element.getAttribute('data-param-primary_text');
const secondary_text = this.element.getAttribute('data-param-secondary_text');
  
      let html = '';
      {
        const is_editor = false;
        const customVariants = product.variants.filter((value) => value["default_variant"] == false);
        const variant = currentVariant ?? product;
        const selected_price = variant.prices[0];
        const desc = (product.description ?? product.description_override ?? variant.description ?? product.default_variant.description ?? "").replaceAll(`
`,"<br>");
        html += `<span class="elProductSelectName">${variant.name ?? product.default_variant.name}</span>`
        if (variant.prices.length == 1) {
          html += `<div class="elProductSelectSinglePriceWrapper"><span class="elProductSelectPrice">${selected_price.name}</span>`
          const compare_at_amount = selected_price.compare_at_amount;
          if (compare_at_amount) {
            html += `<span class="elProductSelectCompareAt">${compare_at_amount}</span>`
          }
          html += `</div>`
        } else {
          html += `<div class="elSelectWrapper`
          if (input_layout_size == "xs") {
            html += ` elProductSelectInputExtraSmall`
          }
          if (input_layout_size == "s") {
            html += ` elProductSelectInputSmall`
          }
          if (input_layout_size == "m") {
            html += ` elProductSelectInputMedium`
          }
          if (input_layout_size == "l") {
            html += ` elProductSelectInputLarge`
          }
          if (input_layout_size == "xl") {
            html += ` elProductSelectInputExtraLarge`
          }
          html += `"><select name="variant_price">`
          const c0 = variant.prices
          const fl1 = new CF2ForloopDrop(c0.length)
          for (const price of c0) {
            const forloop = fl1
            html += `<option `
            if (selected_price.id == price.id) {
              html += `selected`
            }
            html += ` value="${price.id}">${price.name}</option>`
            forloop.next();
          }
          html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div>`
        }
        if (desc != "") {
          html += `<span class="elProductSelectDescription">${desc}</span>`
        }
        if (customVariants.length > 1) {
          html += `<div class="elProductSelectVariantSelectorWrapper">`
          const c2 = product.sorted_property_values
          const fl3 = new CF2ForloopDrop(c2.length)
          for (const property_values of c2) {
            const forloop = fl3
            const property_id = property_values.property_id;
            const select_index = forloop.index0;
            html += `<div class="elSelectWrapper`
            if (input_layout_size == "xs") {
              html += ` elProductSelectInputExtraSmall`
            }
            if (input_layout_size == "s") {
              html += ` elProductSelectInputSmall`
            }
            if (input_layout_size == "m") {
              html += ` elProductSelectInputMedium`
            }
            if (input_layout_size == "l") {
              html += ` elProductSelectInputLarge`
            }
            if (input_layout_size == "xl") {
              html += ` elProductSelectInputExtraLarge`
            }
            html += `"><span class="elProductSelectVariantSelectorTitle">${product.all_properties[property_id]}</span><div style="position:relative;"><select class="elVariantSelector" name="property_${property_id}">`
            const c4 = property_values.value_ids
            const fl5 = new CF2ForloopDrop(c4.length)
            for (const value_id of c4) {
              const forloop = fl5
              html += `<option `
              if (variant.property_value_ids[select_index] == value_id) {
                html += `selected`
              }
              html += ` value="${value_id}">${product.all_properties_values[value_id]}</option>`
              forloop.next();
            }
            html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></div></div>`
            forloop.next();
          }
          html += `</div>`
        }
        html += `<div class="elProductSelectButtonsWrapper"><div data-page-element="Button/V1" class="elBTN elProductSelectAddToCartButton id-Button/V1`
        if (button_layout_size == "xs") {
          html += ` elProductSelectButtonExtraSmall`
        }
        if (button_layout_size == "s") {
          html += ` elProductSelectButtonSmall`
        }
        if (button_layout_size == "m") {
          html += ` elProductSelectButtonMedium`
        }
        if (button_layout_size == "l") {
          html += ` elProductSelectButtonLarge`
        }
        if (button_layout_size == "xl") {
          html += ` elProductSelectButtonExtraLarge`
        }
        html += `" data-liquid-replace="item"><a target="_self" href="#add-to-cart" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">${primary_text}</span></span><span class="elButtonSub"></span></a></div><div data-page-element="Button/V1" class="elBTN elProductSelectBuyNowButton id-Button/V1`
        if (button_layout_size == "xs") {
          html += ` elProductSelectButtonExtraSmall`
        }
        if (button_layout_size == "s") {
          html += ` elProductSelectButtonSmall`
        }
        if (button_layout_size == "m") {
          html += ` elProductSelectButtonMedium`
        }
        if (button_layout_size == "l") {
          html += ` elProductSelectButtonLarge`
        }
        if (button_layout_size == "xl") {
          html += ` elProductSelectButtonExtraLarge`
        }
        html += `" data-liquid-replace="item"><a target="_self" href="#buy-now" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">${secondary_text}</span></span><span class="elButtonSub"></span></a></div></div>`

      }

      this.element.innerHTML = html;

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

window["ProductSelectV2"] = ProductSelectV2

